import { Button, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import useSmoothScrollTo from '../hooks/useSmoothScrollTo'
import { gaCreateEvent } from '../utilities/Analytics'

/**
 * @param scrollToId
 * @param text
 * @param icon
 * @returns {JSX.Element}
 * @constructor
 */
const FaqCategoryScrollButton = ({ scrollToId, text, icon }) => {
    const handleScroll = useSmoothScrollTo(scrollToId, { offset: -30 })

    const onClickHandler = () => {
        handleScroll()
        gaCreateEvent('Click', 'Faq category scroll button clicked', scrollToId)
    }

    return (
        <Col lg='3' xs='6' className='mb-4'>
            <StyledButton variant={'outline-dark'} block onClick={onClickHandler} className='py-4'>
                <span className='d-block pb-3'>
                    <FontAwesomeIcon icon={icon} size='lg' />
                </span>
                <span className='d-block'>{text}</span>
            </StyledButton>
        </Col>
    )
}

const StyledButton = styled(Button)`
    &:hover,
    &:active {
        background-color: var(--light) !important;
        color: var(--dark) !important;
    }
    &:focus {
        box-shadow: none;
    }

    text-transform: uppercase;
    font-weight: 300;
    font-size: clamp(1rem, 3vw, 1.2rem);
    letter-spacing: 1.5px;
`

export default FaqCategoryScrollButton
