import { useEffect } from 'react'

const scriptSrc = 'https://static.landbot.io/landbot-3/landbot-3.0.0.js'

/**
 * useLandbot -hook
 *
 * Loads landbot script and initializes Landbot. Destroy old instance on unmount.
 *
 * @param {string} configUrl The url used to initialize landbot.
 */
const useLandbot = configUrl => {
    useEffect(() => {
        // Try to find existing landbot script tag
        let script = document.querySelector(`script[src="${scriptSrc}"]`)

        if (!script) {
            // Landbot script tag is not yet present. Create it, and initialize landbot after it has loaded
            const scriptTag = document.createElement('script')
            scriptTag.src = scriptSrc
            scriptTag.addEventListener('load', () => {
                window.myLandbot = new window.Landbot.Livechat({
                    configUrl: configUrl,
                })
            })
            document.body.appendChild(scriptTag)
        } else {
            // Landbot script tag is already present. Check LandbotLivechat is present, and create
            // new landbot instance with a new index
            if (window.Landbot) {
                window.myLandbot = new window.Landbot.Livechat({
                    configUrl: configUrl,
                })
            }
        }

        return () => {
            // Destroy instance on unmount
            window.myLandbot?.destroy()
        }
    }, [configUrl])
}

export default useLandbot
